#root {
  display: flex;
  flex: 1;

  --primary-color: #4073b4;
  --secondary-color: #2d617e;
  --dark-color: #2a2a2a;
  --light-color: #ade2ff;

  --background-footer: #090909;
}

/* ---- EXTENDS ---- */
%transition {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

%gradient {
  background: var(--primary-color);
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 75%
  );
}

%text-gradient {
  background: -webkit-linear-gradient(
    var(--primary-color),
    var(--secondary-color)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--primary-color);
}

/* ---- MIXINS ---- */
@mixin media($width, $type: min, $dimension: width) {
  @if map_has_key($grid-breakpoints, $width) {
    $width: map_get($grid-breakpoints, $width);
  }

  @if $type == max {
    $width: $width - 1px;
  }

  @media only screen and (#{$type}-#{$dimension}: $width) {
    @content;
  }
}

// components/_button.scss
@mixin buttonColor($bg, $color, $colorHover, $amount: 10%) {
  background-color: $bg;
  color: $color;

  &:hover,
  &:active,
  &:focus {
    @if $colorHover== 'lighten' {
      background-color: lighten($bg, $amount);
    } @else if $colorHover== 'darken' {
      background-color: darken($bg, $amount);
    }

    color: $color;
  }
}

@mixin pseudo($zIndex: 0, $content: true) {
  @if $content {
    content: '';
  }
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: $zIndex;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@keyframes pulse {
  0% {
    // transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }

  70% {
    // transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    // transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes fadeInMove {
  0% {
    opacity: 0;
    left: -300px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mvBottom {
  0% {
    bottom: -150px;
  }
  100% {
    bottom: 10px;
  }
}

@keyframes mvTop {
  0% {
    top: -150px;
  }
  100% {
    top: 40px;
  }
}

@keyframes dsTop {
  0% {
    top: -150px;
  }
  100% {
    top: 0;
  }
}
