.ant-btn-primary {
  color: white;
  background: #4174b4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #3c7dce;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  opacity: 0.9;
  border: 0;
  box-shadow: 1px 1px 22px 2px rgb(17 17 17 / 20%);
}

.ant-form-item-extra {
  color: #666;
  font-weight: 400;
  font-size: 13px;
}

.ant-form-item-explain {
  div {
    font-size: 12px;
    font-style: italic;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.3) !important;
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
}

.ant-select-item-option-state {
  svg {
    color: green !important;
  }
}
