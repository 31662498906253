@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;

  p,
  span {
    font-family: 'Open Sans', sans-serif;
  }
}

button {
  font-family: 'Inter', sans-serif;
  span {
    font-family: 'Inter', sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Inter', sans-serif;
}

text.highcharts-title {
  font-family: 'Inter', sans-serif;
  color: #4178b2 !important;
  fill: #4178b1 !important;
  font-size: 18px;
  font-weight: 400;
}

.highcharts-container {
  text {
    font-family: 'Inter', sans-serif;
  }
}
