li.ant-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  svg {
    margin-right: 6px;
    width: 18px;
  }
}

.ant-drawer-header-no-title {
  button {
    color: #d6f1ff;
  }
}

.ant-drawer-body {
  padding: 15px 0 0 0;
  background: #111 !important;

  img {
    width: 20%;
  }
}

li.ant-menu-item svg {
  margin-right: 0px !important;
  width: 22px !important;
  font-size: 22px !important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: var(--light-color);
  font-family: 'Inter', sans-serif;

  svg {
    color: var(--light-color);
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-family: 'Inter';
}

.ant-drawer-body.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--light-color) !important;
}

li.ant-menu-item {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05) !important;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgb(173 226 255 / 15%) !important;
  border-radius: 0px 5px 5px 0px;
  border-left: 2px solid #ade2ff;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;

  svg {
    margin-left: -2px !important;
  }
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--light-color) !important;
}

.ant-menu-item svg {
  vertical-align: middle;
}

.ant-drawer-close {
  color: rgb(255 255 255 / 45%);
}

.ant-drawer-header {
  background: #111;
}

.ant-drawer-content-wrapper {
  max-width: 270px;
}

.ant-menu-inline-collapsed {
  li.ant-menu-item {
    padding: 0 calc(50% - 20px / 2) !important;
  }

  svg {
    width: 20px !important;
  }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu
  .ant-menu-item
  .ant-menu-item-icon,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu
  .ant-menu-submenu-title
  .ant-menu-item-icon,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu .ant-menu-item .anticon,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu
  .ant-menu-submenu-title
  .anticon {
  min-width: 22px;
  font-size: 22px;
  transition: font-size 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu
  .ant-menu-item
  .ant-menu-item-icon,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-menu
  .ant-menu-submenu-title
  .ant-menu-item-icon {
  vertical-align: -0.3em;
}
